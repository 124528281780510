/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MediaDto
 */
export interface MediaDto {
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    originalFilename: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDto
     */
    contentType: string;
    /**
     * 
     * @type {Date}
     * @memberof MediaDto
     */
    date: Date;
}

/**
 * Check if a given object implements the MediaDto interface.
 */
export function instanceOfMediaDto(value: object): value is MediaDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('originalFilename' in value) || value['originalFilename'] === undefined) return false;
    if (!('contentType' in value) || value['contentType'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    return true;
}

export function MediaDtoFromJSON(json: any): MediaDto {
    return MediaDtoFromJSONTyped(json, false);
}

export function MediaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'originalFilename': json['originalFilename'],
        'contentType': json['contentType'],
        'date': (new Date(json['date'])),
    };
}

  export function MediaDtoToJSON(json: any): MediaDto {
      return MediaDtoToJSONTyped(json, false);
  }

  export function MediaDtoToJSONTyped(value?: MediaDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'originalFilename': value['originalFilename'],
        'contentType': value['contentType'],
        'date': ((value['date']).toISOString()),
    };
}

