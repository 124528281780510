/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MediaPost200Response,
  PageMediaDto,
} from '../models/index';
import {
    MediaPost200ResponseFromJSON,
    MediaPost200ResponseToJSON,
    PageMediaDtoFromJSON,
    PageMediaDtoToJSON,
} from '../models/index';

export interface MediaGetRequest {
    page?: number;
}

export interface MediaIdGetRequest {
    id: string;
}

export interface MediaPostRequest {
    file?: Blob;
}

/**
 * 
 */
export class MediaApi extends runtime.BaseAPI {

    /**
     * Get all media files
     */
    async mediaGetRaw(requestParameters: MediaGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageMediaDto>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/media`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageMediaDtoFromJSON(jsonValue));
    }

    /**
     * Get all media files
     */
    async mediaGet(requestParameters: MediaGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageMediaDto> {
        const response = await this.mediaGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a media file
     */
    async mediaIdGetRaw(requestParameters: MediaIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mediaIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get a media file
     */
    async mediaIdGet(requestParameters: MediaIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.mediaIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a file
     */
    async mediaPostRaw(requestParameters: MediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaPost200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/media`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload a file
     */
    async mediaPost(requestParameters: MediaPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaPost200Response> {
        const response = await this.mediaPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
