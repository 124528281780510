/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPreviewDto
 */
export interface PostPreviewDto {
    /**
     * 
     * @type {string}
     * @memberof PostPreviewDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostPreviewDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PostPreviewDto
     */
    summary: string;
    /**
     * 
     * @type {Date}
     * @memberof PostPreviewDto
     */
    date: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PostPreviewDto
     */
    visible: boolean;
}

/**
 * Check if a given object implements the PostPreviewDto interface.
 */
export function instanceOfPostPreviewDto(value: object): value is PostPreviewDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('visible' in value) || value['visible'] === undefined) return false;
    return true;
}

export function PostPreviewDtoFromJSON(json: any): PostPreviewDto {
    return PostPreviewDtoFromJSONTyped(json, false);
}

export function PostPreviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPreviewDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'summary': json['summary'],
        'date': (new Date(json['date'])),
        'visible': json['visible'],
    };
}

  export function PostPreviewDtoToJSON(json: any): PostPreviewDto {
      return PostPreviewDtoToJSONTyped(json, false);
  }

  export function PostPreviewDtoToJSONTyped(value?: PostPreviewDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'title': value['title'],
        'summary': value['summary'],
        'date': ((value['date']).toISOString()),
        'visible': value['visible'],
    };
}

