/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MediaDto } from './MediaDto';
import {
    MediaDtoFromJSON,
    MediaDtoFromJSONTyped,
    MediaDtoToJSON,
    MediaDtoToJSONTyped,
} from './MediaDto';

/**
 * 
 * @export
 * @interface PageMediaDto
 */
export interface PageMediaDto {
    /**
     * Current page
     * @type {number}
     * @memberof PageMediaDto
     */
    page: number;
    /**
     * Total pages
     * @type {number}
     * @memberof PageMediaDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<MediaDto>}
     * @memberof PageMediaDto
     */
    items: Array<MediaDto>;
}

/**
 * Check if a given object implements the PageMediaDto interface.
 */
export function instanceOfPageMediaDto(value: object): value is PageMediaDto {
    if (!('page' in value) || value['page'] === undefined) return false;
    if (!('totalPages' in value) || value['totalPages'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function PageMediaDtoFromJSON(json: any): PageMediaDto {
    return PageMediaDtoFromJSONTyped(json, false);
}

export function PageMediaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageMediaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'totalPages': json['totalPages'],
        'items': ((json['items'] as Array<any>).map(MediaDtoFromJSON)),
    };
}

  export function PageMediaDtoToJSON(json: any): PageMediaDto {
      return PageMediaDtoToJSONTyped(json, false);
  }

  export function PageMediaDtoToJSONTyped(value?: PageMediaDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'page': value['page'],
        'totalPages': value['totalPages'],
        'items': ((value['items'] as Array<any>).map(MediaDtoToJSON)),
    };
}

